






















import Vue from "vue";

import Footer from "@/components/navigation-footer.vue";
export default Vue.extend({
  components: { Footer },
  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
  },
});
